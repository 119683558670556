import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"

import Accordion from "../../components/template-partials/global-components/accordion/accordion"
import StoriesOfHopeSubmenu from "../../components/template-partials/stories-of-hope/stories-of-hope-submenu"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import headingBg from "../../images/7.0-headerbg.jpg"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faDownload } from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "../../components/VideoPlayer"


import bigAdventures from "../../images/7.4_the-big-adventures-of-little-skivolo.jpg";
import antiBodyBook from "../../images/3.4.1_antibodybook.jpg";
import bookOfFun from "../../images/7.4_little-skivolo-big-book-of-fun.jpg";
import zara from "../../images/Zaratakesoff_v23.jpg";

import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const CarsonsBattlePage = () => {
  return (
    <Layout>
      <SEO title="Carson's Battle With Neuroblastoma" description="Learn about Carson's battle with Neuroblastoma" />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={zara} alt="How is Neuroblastoma Diagnosed?">
        <h1 className="section__heading section__heading--green h1">Carson's Battle With Neuroblastoma</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Stories of Hope`}>
              <StoriesOfHopeSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">Carson's Battle With Neuroblastoma</h2>
                <p>United Therapeutics Oncology is proud to partner with Carson.<strong><em>Carson's Story</em></strong>. This educational initiative supports and provides comprehensive resources to families touched by neuroblastoma, no matter where they are on the patient journey—newly diagnosed, in treatment, or after treatment and beyond.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="row row-75">
            <div className="columns">
              <h2 className="h2 tc-barney jost-med">Carson's Video</h2>
              <VideoPlayer
                embedId="871112390"
                title="Still Standing Together"
                data="h=bd44571455"
                className="left-Video-Player"
              />
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns">
            <p>Learn more about the neuroblastoma journey by reading & downloading the Braving Neuroblastoma resources. </p>
            <ul className="ul--standard lg_mt-1">
              <li><a href="#resources"><FontAwesomeIcon icon={faLink}/> Download Braving Neuroblastoma resources</a></li>
            </ul>
          </div>
        </div>
      </Section>

      <Section id="resources" style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Braving Neuroblastoma Resources</h3>
            <h4 className="h4 tc-viridian jost-med lg_mt-3">A series of four beautifully illustrated books</h4>
            <p>Each book highlights different stages of the high-risk neuroblastoma journey.</p>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-3">

          <div className="columns large-12 small-12">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={bigAdventures} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">The Big Adventures of Little Skivolo</h3>
                <p><em>The Big Adventures of Little Skivolo</em> helps families understand diagnosis and treatment.</p>
                <p className="lg_mt-2">
                  <a href="/BigAdventures_English.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/BigAdventures_Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (SPA)" target="_blank" rel="noreferrer" className="btn--link no-ext">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/BigAdventures_French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Big Adventures of Little Skivolo (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={antiBodyBook} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">The Next Big Adventure of Little Skivolo</h3>
                <p><em>The Next Big Adventure of Little Skivolo</em> focuses specifically on the antibody therapy phase of treatment.</p>
                <p className="lg_mt-2">
                  <a href="/Next-Big-Adventures-English.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/Next-Big-Adventures-Spanish.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (SPA)" target="_blank" rel="noreferrer" className="btn--link no-ext">Descargar en Español <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/Next-Big-Adventures-French.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="The Next Big Adventure of Little Skivolo (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={bookOfFun} alt="The Big Adventures of Little Skivolo" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">Little Skivolo’s Big Book of Fun</h3>
                <p><em>Little Skivolo’s Big Book of Fun</em>, an activity book to help entertain kids and families during hospital stays.</p>
                <p className="lg_mt-2">
                  <a href="/big-book-english.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Little Skivolo’s Big Book of Fun (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a href="/big-book-french.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Little Skivolo’s Big Book of Fun (FRE)" target="_blank" rel="noreferrer" className="btn--link no-ext">Télécharger en Français <FontAwesomeIcon icon={faDownload}/></a>
                </p>
              </div>
            </div>
          </div>

          <div className="columns large-12 small-12 lg_mt-4 mt-4">
            <div className="row">
              <div className="columns small-12 large-shrink text-center">
                <img src={zara} alt="Zara Takes Off" style={{maxWidth: `171px`}}/>
              </div>
              <div className="columns">
                <h3 className="h3 jost-med tc-viridian">Zara Takes Off</h3>
                <p><em>Zara Takes Off</em> inspires hope and encouragement as families transition to life after treatment. <strong>This new release includes a personal foreword penned by Leah, sharing details of her personal journey.</strong></p>
                <p className="lg_mt-2">
                  <a href="/Zara_Survivorship_Book_and_Foreword_PDF.pdf" data-gtm-event-category="PDF" data-gtm-event-action="Download" data-gtm-event-label="Zara Takes Off (ENG)" target="_blank" rel="noreferrer" className="btn--link no-ext">Download in English <FontAwesomeIcon icon={faDownload}/></a>
                  <a
                    href="/Zara_Takes_Off_Spanish.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (SPA)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Descargar en Español <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <a
                    href="/Zara_Takes_Off_French.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Zara Takes Off (FRE)"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Télécharger en Français{" "}
                    <FontAwesomeIcon icon={faDownload} />
                  </a>
                </p>
              </div>
            </div>
          </div>

        </div>
        <div className="row row--inner align-middle lg_mt-4">
          <div className="columns">
            <p><Link to="/support-and-community/downloadable-resources/">Find even more resources on the Downloadable Resources & Videos page &rsaquo; </Link></p>
          </div>
        </div>
      </Section>


      <InteriorPageFooter>

        <UpNextCard supressTopic="true" topic="Explore more of neuroblastoma-info.com" title="Find the information, resources, and support you need" lead="Neuroblastoma-info.com is a family-friendly website that includes a comprehensive library of information and resources for every step in the neuroblastoma journey." url="/" />


      </InteriorPageFooter>

    </Layout>
  )
}

export default CarsonsBattlePage
